import React, { Component } from 'react';

class HTMLComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: '',
      loading: true
    };
  }

  componentDidMount() {
    // Fetch the HTML file
    fetch('./webpage.html')
      .then(response => response.text())
      .then(html => {
        this.setState({ htmlContent: html, loading: false });
      })
      .catch(error => console.error('Error fetching HTML:', error));
  }

  render() {
    const { htmlContent, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
  }
}

export default HTMLComponent;
